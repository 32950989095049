import React from "react"

import Playlist from "../components/playlist"

import imgbanner from "../images/index/thumb/be_better.jpg"
import allcategories from "../content/categorytitles.json"
import videolist from "../content/playlistbebetter.json"


const BebetterPage = ({location}) => (
  <Playlist
    location={location}
    headingClassName={"color-text-primary"}
    path={"be_better"}
    bannerimg={imgbanner}
    title={allcategories.be_better.title}
    writeup={allcategories.be_better.description} 
    videolist={videolist} />
)

export default BebetterPage
